import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { platformBrowser } from '@angular/platform-browser';
import { extendConfiguration } from './config';
import * as Sentry from '@sentry/browser';
import '@triarc-labs/applink/include';
import { setScheduleSettings } from '@triarc-labs/tl-planning-canvas';

extendConfiguration().then(() => {
  if (environment.production && !environment.routerEnableTracing) {
    enableProdMode();
  }

  if (environment.sentry) {
    Sentry.init({
      dsn: environment.sentry || undefined,
      environment: environment.envName || undefined,
      release: environment.version,
      sendDefaultPii: true,
    });
  }
  setScheduleSettings(environment.scheduleSettings);
  import('./app/app.module').then((m) => {
    platformBrowser()
      .bootstrapModule(m.AppModule)
      .catch((err) => console.log(err));
  });
});
